.content-helpdesk{
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0px 20px;
  padding: 40px;
}
.ch-left{
  background-color: #706154;
  padding: 20px 25px;
}
.helpTitle h2{
  font-family: "Raleway", sans-serif; 
  font-size: 20px;
  text-transform: uppercase;
  line-height: 26px;
  margin: 0px; padding: 0px;
  margin-top: 10px;
}
.helpTitle h3{
  font-family: "Raleway", sans-serif; 
  font-size: 24px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0px; padding: 0px;
}
.tabHeader table tr td{
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 5px;
  height: 30px;
  font-size: 15px;
  border-bottom: 1px solid #606060;
}
.tabCHelpdesk tr td{
  padding: 5px;
  font-size: 14px;
}

.embla_helpdesk {
  margin: auto;
  --slide-spacing: 1rem;
  --slide-size: 100%;
}
.embla__viewport_helpdesk {
  overflow: hidden;
  border-bottom: 1px solid #606060;
}
.embla__container_helpdesk {
  display: flex;
  touch-action: pan-x pinch-zoom;
  flex-direction: column;
}
.embla__slide_helpdesk{
  margin: 10px 0;
}

.embla__nav__helpdesk{
  position: relative;
  padding: 10px 0 0 0;
  width: 100%;
  bottom: 0px;
  text-align: right;
}