.clock_label {
    font-weight: 500;
    font-size: medium;
  }
  .analog_clock {
    width: 150px;
    height: 150px;
    margin: -30px auto 0;
    padding-top: 1px;
    padding-right: 1px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    position: relative;
  }
  
  .analog_clock .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ffffff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    z-index: 10;
    box-shadow: 0 2px 4px -1px black;
  }
  
  .analog_clock .hour-hand {
    position: absolute;
    z-index: 5;
    width: 6px;
    height: 30px;
    background: #ffffff;
    top: 40px;
    transform-origin: 50% 35px;
    left: 50%;
    margin-left: -2px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }
  
  .analog_clock .minute-hand {
    position: absolute;
    z-index: 6;
    width: 4px;
    height: 50px;
    background: #ffffff;
    top: 20px;
    left: 50%;
    margin-left: -2px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    transform-origin: 50% 55px;
  }
  
  .analog_clock .second-hand {
    position: absolute;
    z-index: 7;
    width: 2px;
    height: 70px;
    background: red;
    top: 2px;
    lefT: 50%;
    margin-left: -1px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    transform-origin: 50% 72px;
  }
  
  .analog_clock span {
    display: inline-block;
    position: absolute;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    z-index: 4;
  }
  
  .analog_clock .h_12 {
    top: 15px;
    left: 50%;
    margin-left: -9px;
  }
  .analog_clock .h_3 {
    top: 60px;
    right: 17px;
  }
  .analog_clock .h_6 {
    bottom: 15px;
    left: 50%;
    margin-left: -5px;
  }
  .analog_clock .h_9 {
    left: 17px;
    top: 60px;
  }
  
  .analog_clock .diallines {
    position: absolute;
    z-index: 2;
    width: 2px;
    height: 5px;
    background: #ffffff;
    left: 50%;
    margin-left: -1px;
    transform-origin: 50% 73px;
  }
  .analog_clock .diallines:nth-of-type(5n) {
    position: absolute;
    z-index: 2;
    width: 4px;
    height: 12px;
    background: #ffffff;
    left: 50%;
    margin-left: -1px;
    transform-origin: 50% 73px;
  }
  
  .analog_clock .date {
    position: relative;
    width: 100%;
    color: #ffffff;
    text-align: center;
    margin-top: 155px;
  }
  .analog_clock .time {
    position: relative;
    width: 100%;
    color: #ffffff;
    text-align: center;
  }
  table.wclock tr{
    height: 250px;
  }