#license{
  position: absolute;
  background: #ffffff;
  top: 0px;
  height: 100vh;
  left: 0px;
  width:100%;
  z-index:99999;
  padding: 0px;
}
.content-sn{
  padding: 150px 0;
}
.content-sn h2{
  color: #111111;
  text-align: center;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
}
.sn{
  text-align: center;
}
.sn input{
  width: 100px;
  padding: 20px;
  font-size: 20px;
  color: #111111;
}
.reg{
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 30px;
  width: 200px;
  left:50%;
  margin-left: -100px;
  padding: 10px 20px;
  border-radius: 15px;
  border: 0px solid rgba(0, 0, 0, 0);;
}