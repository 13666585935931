#welcome{
  position: absolute;
  background: #ffffff;
  top: 0px;
  height: 100vh;
  left: 0px;
  width:100%;
  z-index:99999;
  padding: 0px;
}
.continue{
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 30px;
  width: 200px;
  left:50%;
  margin-left: -100px;
  padding: 10px 20px;
  border-radius: 15px;
  border: 0px solid rgba(0, 0, 0, 0);;
}
.continue:focus{
  outline: none;
  border: 1px solid #ffffff;
}
.row, 
.col-md-12{
  padding: 0px;
  margin: 0px;
}