
.property-left{
  background-color: #706154;
  padding: 30px 0px;
}
.plTitle h2{
  font-family: "Raleway", sans-serif; 
  font-size: 23px;
  text-transform: uppercase;
  line-height: 30px;
  margin: 0px 0px; padding: 0px 30px;
  text-align: right !important;
  width: 100%;

}
.plTitle h3{
  font-family: "Raleway", sans-serif; 
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0px 30px; padding: 0px;
  text-align: right;
}
.plMenu{
  width: 100%;
  padding: 50px 0px 20px 20px;
}
.plMenu1{
  position: relative;
  width: 100%;
  padding: 0px 20px 0 0;
  
}
.plMenu button,
.plMenu1 button{
  background: none;
  color: #ffffff;
  font-size: 14px;
  border: 0px solid;
  width: 100%;
  padding: 4px 30px;
  margin-bottom: 0px;
  text-align: right;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.plMenu1 button{
  padding: 7px;
}

.plMenu button:focus,
.plMenu1 button:focus{
  outline: none;
  border-bottom: 1px solid #ffffff;
}

.tableHelpdesk thead tr{
  border-bottom: 1px solid #3e3e3e;
}
.tableHelpdesk thead tr th{
  padding: 0 15px 15px 15px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: normal;
}
.tableHelpdesk tbody tr td{
  padding: 20px 15px 10px 15px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: normal;
}
.page{
  text-align: center;
  border: 1px solid red;
}