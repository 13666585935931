.content-subpage{
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0px 20px;
  padding: 0px;
}
.content-subpage h2{
  font-family: "Raleway", sans-serif; 
  font-size: 20px;
  text-transform: uppercase;
  line-height: 26px;
  margin: 0px; padding: 0px;
  padding-top: 20px;
  width: 100%;
  text-align: center;
}

.embla {
  overflow: hidden;
  margin: 0px;
}
.embla__viewport:focus{
  outline: none;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}
.embla__nav{
  position: absolute;
  padding: 20px;
  width: 100%;
  bottom: 0px;
  text-align: right;
}
.embla__prev1,
.embla__next1{
  background: #000000;
  border: 1px solid #ffffff;
  border-radius: 15px;
  padding: 10px;
  margin: 0 5px;
}
.btnFNB{
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #333333;
  padding: 7px 15px;
  font-weight: bold;
  border-radius: 15px;
  margin:0 7px;
  outline: none;
  font-size: 14px;
}
.btnFNB:focus{
  outline: none;
  border: 1px solid #ffffff;
}
.btnHome{
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #333333;
  padding: 10px 20px;
  border-radius: 15px;
  margin:0 7px;
  outline: none;
}
.btnHome:focus{
  outline: none;
  border: 1px solid #ffffff;
}