@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

body,#root, h1, h2, h3, h4{
  padding: 0px;
  margin: 0px;
}
.row, 
.col-md-5, 
.col-md-7{
  padding: 0px;
  margin: 0px;
}
#root{
  overflow: hidden;
  height: 100vh;
}
*{
  font-family: "Raleway", sans-serif;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
}
h2{
  font-family: "Caveat", cursive;
  font-size: 40px;
  font-weight: normal;
  margin-bottom: 15px;
}
.tv{
  margin: 0px;
  width: 100%;
  height: 100vh;
}
.logo{
  padding: 20px;
  margin-left: 10px;
}
.logo img{
  height: 90px;
}
.header{
  padding: 0px 30px;
}
.footer{
  text-align: center;
  padding: 0px 10px;
}

.bmenu{
  background: none;
  border: 0px solid;
  margin: 5px 0px;
  height: 60px;
  padding: 10px;
  min-width: 60px;
  border: 1px solid rgba(0, 0, 0, 0);
}
.bmenu:focus{
  border: 1px solid rgba(219, 218, 218, 0.5);
  outline: 0;
  border-radius: 15px;
}
.bbutton{
  background: none;
  border: 0px solid;
  margin: 5px;
  height: 60px;
  padding: 10px;
  min-width: 60px;
}
.bbutton:focus{
  border-bottom: 1px solid rgba(219, 218, 218, 0.5);
  outline: 0;
  padding-left: 0px;
}
.header-right{
  text-align: right;
}
.content-left{
  padding: 80px 40px 10px 40px;
}
.content-right{
  text-align: right;
  padding: 40px 40px 10px 40px;
}
.content-setting{
  position: relative;
  height: 300px;
  text-align: center;
  padding: 80px 40px 10px 40px;
}
.content-transport{
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0px 20px;
  padding: 40px;
}
.carName{
  background-color: #706154; /* #C60382; */
  padding: 30px 10px 30px 30px;
  width: 100%;
  height: 170px;
}
.carName h2{
  font-family: "Raleway", sans-serif; 
  font-size: 38px;
  text-transform: uppercase;
  margin: 0px; padding: 0px;
  line-height: 36px;
}
.carName h3{
  font-family: "Raleway", sans-serif; 
  font-size: 42px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0px; padding: 0px;
  margin-bottom: 7px;
}
.car-thumb{
  position: relative;
  width: 100%;
  border: 0px solid;
  padding: 15px 40px 5px 40px;
  text-align: right;
  background-color: #000000;
}
.car-thumb:focus{
  background-color: #333333;
  outline: none;
}
.car-thumb h3{
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
}
.car-thumb h3 span{
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  margin-right: 1px;
  margin-bottom: 20px;
  position: relative;
  
}
.car-thumb h4{
  font-size: 10px;
  text-transform: uppercase;
}
.car-thumb div{
  padding: 0px; margin: -5px 0px;
}
.car-thumb div img{
  height: 120px;
}
.carPhoto{
  background: url('../lib/images/transport/bg-border.png') no-repeat center right;
  position: absolute;
  z-index: 99999;
  top: 30px;
  right: 0px;
}
.carPhoto img{
  width: 700px;
}
.carSpec{
  margin-top: 170px;
  padding: 30px 10px 30px 40px;
}
.carSpec h2{
  font-family: "Raleway", sans-serif; 
  font-size: 30px;
  font-weight: normal;
}
.carSpec h2 span{
  font-family: "Raleway", sans-serif; 
  font-size: 34px;
  font-weight: bold;
}
.carSpec p{
  font-size: 16px;
}
.btnCarOrder{
  position: absolute;
  background: none;
  border: 1px solid #333333;
  padding: 15px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 15px;
  margin-top: -80px;
  right: 50px;
}
.btnCarOrder:focus{
  border: 1px solid #cccccc;
  outline: none;
}
.weather{
  position: relative;
  width: 100%;
  padding: 0px 20px 0 0;
  height: 80px;
}
.weather #weather_city{
  width: 150px;
  position: absolute;
  margin-top: 18px;
  right: 190px;
  line-height: 18px;
  font-size: 14px;
  text-shadow: 4px 4px 10px #000000;
}
.weather #weather_time{
  width: 90px;
  position: absolute;
  margin-top: 28px;
  line-height: 24px;
  font-size: 40px;
  right: 85px;
  text-shadow: 4px 4px 10px #000000;
}
.weather #weather_icon{
  position: absolute;
  right: 10px;
}
.weather #weather_temp{
  font-size: 16px;
  margin: 20px 0 0 0px;
  text-shadow: 4px 4px 10px #000000;
}
.datetime{
  font-size: 24px;
  margin: -10px 10px 20px 0;
}
.datetime span{
  font-size: 50px;
  margin-left: 10px;
}





.menu{
  padding-left: 200px;
}
.menu ul li{
  float: right;
  list-style: none;
}
.menu ul li button{
  background:none;
  border: 0px solid;
}

.propertyInfo{
  background: none;
  color: #ffffff;
  border: 0px solid ;
}

